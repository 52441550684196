import React, { Component } from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <div className="py-5 container">
          <div className="card mt-5">
            <div className="p-md-5 p-3">
              <h1 className="mb-5">О проекте</h1>
              <p>
                В связи с эпидемиологической обстановкой, с 23 марта в Москве
                ограчинено передвижению жителей. Одним из "легальных" способов
                без опаски выходить из дома - прогулки с собаками. Не у каждого
                из нас есть собака, а выходить на улицу без риска получения
                штрафа хочется всем. Так у меня родилась идея создать сайт, с
                помощью которого, люди, у которых пока нет собаки, получили бы
                возможность легально выходить на прогулки.
              </p>
              <span
                role="img"
                aria-label=""
                className="text-center d-block my-3"
              >
                🐩 🐕 🐕‍🦺 🦮 🐩 🐕 🐕‍🦺 🦮 🐩 🐕 🐕‍🦺 🦮
              </span>
              <p className="mb-5">
                В москве и области находится большое количество приютов и
                специализированных центров для бездомных животных. Кроме того -
                из разных уголков мира приходят{' '}
                <a
                  target="_blank"
                  href="https://esquire.ru/articles/172643-sobachiy-priyut-vo-floride-vpervye-opustel-blagodarya-karantinu-vseh-sobak-razobrali-novye-vladelcy/"
                >
                  такие
                </a>{' '}
                новости, и мне показалось это отличной возможностью помочь людям
                найти друзей, и компанию в это непростое время, а животным - дом
                и любящих хозяев. Надеюсь, у меня получится.
              </p>
              <h5 className="mt-3">
                Если мне понравилась собака, а ее уже кто-то выгуливает?
              </h5>
              <p className="mb-5">
                Я уверен, что если вы <Link to="/">посмотрите</Link> на собачек
                внимательнее - вам обязательно понравится еще одна.
              </p>
              <h5 className="mt-3">
                Так как же я могу взять собаку в краткосрочную аренду?
              </h5>
              <p className="mb-5">
                В краткосрочную - надеюсь, что никак. Но на этом сайте вы можете
                обрести друга на долгие годы. Если у вас все-таки нет такой
                возможности, но вы бы хотели иногда гулять с четвероногими - в
                обычное время приютам часто нужны волонтеры для выгула собак.
              </p>
              <h5 className="mt-3">Это законно?</h5>
              <p className="mb-5">
                Надеюсь, что да.{' '}
                <span role="img" aria-label="ответственность">
                  🙈
                </span>
              </p>
              <h5 className="mt-3">Что-нибудь еще?</h5>
              <p className="mb-5">
                Если вы представляете какой-нибудь приют, и хотите добавить на
                сайт своих питомцев. Или же у вас есть какие-то претензии -
                пожалуйста <Link to="/collaborate/">напишите мне</Link>.
              </p>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default IndexPage;
